
import { defineComponent, onMounted, ref } from 'vue'
import { EditOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons-vue'
import AddTeamModel from './addTeamModel.vue'
import { getTeamList, deleteTeam } from '@/api/index'
import { showConfirm } from '@/hooks/common-hooks'
import { message } from 'ant-design-vue'
export default defineComponent({
  components: {
    EditOutlined,
    DeleteOutlined,
    PlusCircleOutlined,
    AddTeamModel
  },
  setup () {
    const model = ref()
    const teamSetList = ref([])
    const modelVisible = ref(false)
    const team_id = ref(null)
    // 获取班组列表
    const getTeamLists = () => {
      getTeamList({}).then(res => {
        teamSetList.value = res.data
      })
    }
    const openModel = () => {
      team_id.value = null
      modelVisible.value = true
    }
    const editTeam = (value: any) => {
      team_id.value = value
      modelVisible.value = true
      model.value.getTeamDetailHttp(value)
    }
    const deleteTeamHttp = (id: any) => {
      showConfirm({
        content: '确定要删除该班组吗?',
        callBack: () => {
          deleteTeam({
            id
          }).then(() => {
            message.success('删除成功')
            getTeamLists()
          })
        }
      })
    }
    onMounted(() => {
      getTeamLists()
    })
    return {
      teamSetList,
      modelVisible,
      openModel,
      team_id,
      editTeam,
      getTeamLists,
      model,
      deleteTeamHttp
    }
  }
})
