import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")
  const _component_a_select = _resolveComponent("a-select")
  const _component_a_input = _resolveComponent("a-input")
  const _component_manger_page_header = _resolveComponent("manger-page-header")
  const _component_a_table = _resolveComponent("a-table")
  const _component_team_setting = _resolveComponent("team-setting")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_manger_page_header, {
      value: _ctx.btnIndex,
      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.btnIndex = $event)),
      btnList: _ctx.btnList,
      onChangeIndex: _ctx.changeIndex
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_select, {
          value: _ctx.team_id,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.team_id = $event)),
          allowClear: "",
          class: "myselect box-shadow",
          style: {"width":"140px"},
          placeholder: "请选择班组",
          onChange: _ctx.changeSelect
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.teamList, (item) => {
              return (_openBlock(), _createBlock(_component_a_select_option, {
                key: item.id
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.team), 1)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["value", "onChange"]),
        _createVNode(_component_a_input, {
          placeholder: "请输入ID、账号或姓名搜索",
          value: _ctx.search,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.search = $event)),
          onKeyup: _withKeys(_ctx.fetchSearch, ["enter"])
        }, null, 8, ["value", "onKeyup"])
      ]),
      _: 1
    }, 8, ["value", "btnList", "onChangeIndex"]),
    (_ctx.btnIndex == 0)
      ? (_openBlock(), _createBlock(_component_a_table, {
          key: 0,
          columns: _ctx.columns,
          "data-source": _ctx.data,
          pagination: _ctx.pagination,
          class: "table",
          rowKey: "id",
          onChange: _ctx.changePage
        }, {
          team: _withCtx(({record}) => [
            _createVNode(_component_a_select, {
              value: record.team_id,
              "onUpdate:value": ($event: any) => (record.team_id = $event),
              style: {"width":"150px"},
              placeholder: "请选择班组",
              onChange: ($event: any) => (_ctx.teamChange(record.id, record.role_id, $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.teamList, (item) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: item.id
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.team), 1)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 2
            }, 1032, ["value", "onUpdate:value", "onChange"])
          ]),
          identity: _withCtx(({record}) => [
            _createVNode(_component_a_select, {
              value: record.role_id,
              "onUpdate:value": ($event: any) => (record.role_id = $event),
              style: {"width":"150px"},
              placeholder: "请选择身份",
              onChange: ($event: any) => (_ctx.majorChange(record.id, record.team_id, $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.getRoleList(record), (item) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: item.id
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.role ? item.role : '无'), 1)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 2
            }, 1032, ["value", "onUpdate:value", "onChange"])
          ]),
          _: 1
        }, 8, ["columns", "data-source", "pagination", "onChange"]))
      : (_openBlock(), _createBlock(_component_team_setting, { key: 1 }))
  ], 64))
}