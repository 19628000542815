
import { defineComponent, ref, reactive, toRefs, onMounted } from 'vue'
import { btnAndSearchHooks } from '@/hooks/btn-and-search-hooks'
import mangerPageHeader, { BtnProps } from '@/components/btn-and-search.vue'
import TeamSetting from '@/views/teamManger/components/teamSetting.vue'
import { showConfirm } from '@/hooks/common-hooks'
import { getTeamAccountList, getTeamList, editTeamAccount, getTeamRole } from '@/api/index'
const btnList: BtnProps[] = [
  {
    id: 1,
    btnName: '班组列表'
  },
  {
    id: 2,
    btnName: '班组设置'
  }
]
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 80
  },
  {
    title: '账号',
    dataIndex: 'account',
    key: 'account'
  },
  {
    title: '姓名',
    dataIndex: 'nickname',
    key: 'nickname'
  },
  {
    title: '专业',
    dataIndex: 'major',
    key: 'major'
  },
  {
    title: '班组',
    dataIndex: 'team',
    key: 'team',
    slots: { customRender: 'team' }
  },
  {
    title: '身份',
    dataIndex: 'role',
    key: 'role',
    slots: { customRender: 'identity' }
  }
]
interface PaginationProps {
  current: number;
  pageSize: number;
  total: number;
}
const pagination: PaginationProps = {
  current: 1,
  pageSize: 10,
  total: 0
}
export default defineComponent({
  name: 'TeamManger',
  components: {
    mangerPageHeader,
    TeamSetting
  },
  setup () {
    const tableData = reactive({
      columns,
      data: [],
      pagination: { ...pagination },
      team_id: null,
      search: '',
      teamList: [],
      majorList: [],
      roleList: []
    })
    // 获取列表
    const getTeamTableList = () => {
      getTeamAccountList({
        team_id: tableData.team_id,
        search: tableData.search.trim(),
        page: tableData.pagination.current,
        pagesize: tableData.pagination.pageSize
      }).then(res => {
        tableData.data = res.data
        tableData.pagination.total = res.result
      })
    }
    // 获取班组列表
    const getTeamLists = () => {
      getTeamList({}).then(res => {
        const list = res.data
        list.push({ id: 0, team: '无' })
        tableData.teamList = list
      })
    }
    // 获取角色列表
    const getTeamRoles = () => {
      getTeamRole({}).then(res => {
        const list = res.data
        // list.push({ id: 0, role: '无' })
        tableData.roleList = list
      })
    }
    const httpRequest = () => {
      getTeamRoles()
      getTeamLists()
      getTeamTableList()
    }
    const { changeIndex, btnIndex } = btnAndSearchHooks(httpRequest)
    // 翻页
    const changePage = (pagination: PaginationProps) => {
      tableData.pagination.current = pagination.current
      getTeamTableList()
    }
    // 搜索
    const fetchSearch = () => {
      tableData.pagination.current = 1
      getTeamTableList()
    }
    const changeSelect = (value: any) => {
      tableData.team_id = value
      getTeamTableList()
    }
    const updateTeam = (_obj: any) => {
      editTeamAccount(_obj).then(res => {
        console.log(res, '222')
        if (res.sub_code === 'Arim_TeamUserRoleExit') {
          showConfirm({
            content: `${res.data}已为该小组的班长，是否确认修改?`,
            callBack: () => {
              updateTeam(Object.assign(_obj, { confirm: true }))
            },
            cancelBack: () => {
              getTeamTableList()
            }
          })
        } else {
          getTeamTableList()
        }
      })
    }
    const teamChange = (id: any, role_id: any, value: any) => {
      updateTeam({ account_id: id, role_id: role_id, team_id: value })
    }
    const majorChange = (id: any, team_id: any, value: any) => {
      updateTeam({ account_id: id, team_id: team_id, role_id: value })
    }
    const checkeList = (role_list: any, id: number) => {
      return role_list.filter((item: any) => item.id === id)
    }
    // 筛选角色
    const getRoleList = (items: any) => {
      console.log(tableData.roleList)
      if (items.major_id === 0) {
        return [{ id: 0, role: '无' }]
      } else {
        const _list = JSON.parse(JSON.stringify(tableData.roleList)).filter((item: any) => {
          return item.id === items.major_id
        }).map((item: any) => item.role_list)[0]
        const _arr = checkeList(_list, items.role_id)
        if (_arr.length === 0) {
          items.role_id = 0
          _list.unshift({ id: 0, role: '无' })
        }
        return _list
      }
    }
    onMounted(() => {
      httpRequest()
    })
    return {
      btnList,
      btnIndex,
      changeIndex,
      changePage,
      ...toRefs(tableData),
      fetchSearch,
      changeSelect,
      teamChange,
      majorChange,
      getRoleList
    }
  }
})
