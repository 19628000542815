
import { defineComponent, UnwrapRef, reactive, ref, onMounted, computed, watch } from 'vue'
import { getTeamMajor, createTeam, editTeam, getTeamDetail } from '@/api/index'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { Modal } from 'ant-design-vue'
interface FormProps {
  team: string;
  team_num: string;
  major_id: number | null;
}
export default defineComponent({
  props: ['visible', 'team_id', 'getTeamLists'],
  setup (props, ctx) {
    const formRef = ref()
    const formState: UnwrapRef<FormProps> = reactive({
      team: '',
      team_num: '',
      major_id: null
    })
    const majorList = ref([])
    // 表单验证
    const rules = {
      team: [{ required: true, trigger: 'blur', message: '请输入班组名称', whitespace: true }],
      team_num: [{ required: true, trigger: 'blur', message: '请输入班组编号', whitespace: true }],
      major_id: [{ type: 'number', required: true, trigger: 'blur', message: '请选择班组类型' }]
    }
    // 获取专业列表
    const getTeamMajors = () => {
      getTeamMajor({}).then(res => {
        majorList.value = res.data
      })
    }
    const handleCancel = () => {
      ctx.emit('update:visible', false)
      formState.team = ''
      formState.team_num = ''
      formState.major_id = null
    }
    // 创建班组
    const createTeamHttp = () => {
      createTeam(formState).then(res => {
        handleCancel()
        props.getTeamLists()
      })
    }
    // 编辑班组
    const editTeamHttp = () => {
      editTeam(Object.assign(formState, { id: props.team_id })).then(res => {
        handleCancel()
        props.getTeamLists()
      })
    }
    // 提交数据
    const onSubmit = (callback: () => {}) => {
      formRef.value.validate().then(() => {
        if (props.team_id) {
          // 编辑
          editTeamHttp()
        } else {
          // 创建
          createTeamHttp()
        }
      }).catch((error: ValidateErrorEntity<FormProps>) => {
        console.log('error', error)
      })
    }
    const getTeamDetailHttp = (id: any) => {
      getTeamDetail({ id: parseInt(id) }).then(res => {
        formState.team = res.data.team
        formState.team_num = res.data.team_num
        formState.major_id = res.data.major_id
      })
    }
    onMounted(() => {
      getTeamMajors()
    })
    return {
      rules,
      formState,
      handleCancel,
      majorList,
      formRef,
      onSubmit,
      getTeamDetailHttp
    }
  }
})
